import React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { FaHome, FaEdit, FaSignOutAlt, FaListAlt, FaTrophy, FaUserTie } from 'react-icons/fa';
import { resetUser } from './store';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function RegolamentoPmr() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.clear();
        dispatch(resetUser());
        navigate('/loginPmr');
    };

    return (
        <>
            <Navbar fixed="top" expand="false" style={{
                borderBottom: '1px solid #dee2e6',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(5px)',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
                <Container>
                    <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                    <img src="/pmr.png" alt="Pmr Logo" style={{ height: '40px', marginRight: '10px' }} />
                        Pmr Montano
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={() => navigate('/gestione-attivitaPmr')} style={{ color: 'black' }}>
                                <FaEdit /> Gestione Attività
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/elenco-attivitaPmr')} style={{ color: 'black' }}>
                                <FaListAlt /> Elenco Attività
                            </Nav.Link>

                            {/* Dropdown per le classifiche */}
                            <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                            <NavDropdown.Item onClick={() => navigate('/classifica-attivitaPmr2025')}>Classifica Attivatori 2025</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-attivitaPmr')}>Classifica Attivatori 2024</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2025Pmr')}>Classifica Cacciatori 2025</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2024Pmr')}>Classifica Cacciatori 2024</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link onClick={() => navigate('/direttivoPmr')} style={{ color: 'black' }}>
                                <FaUserTie /> Direttivo
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/homePmr')} style={{ color: 'black' }}>
                                <FaHome /> Home
                            </Nav.Link>
                            <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                                <FaSignOutAlt /> Logout
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "90vh",
                    backgroundImage: 'url("/login_radio.webp")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    paddingTop: '56px'
                }}
            >
                <Container style={{
                    padding: 0,
                    maxWidth: "100%",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <div className="text-center" style={{
                        backgroundColor: "rgba(192, 192, 192, 0.7)",
                        padding: "20px",
                        borderRadius: "15px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        maxWidth: "600px"
                    }}>
                                <img src="/pmr.png" alt="Pmr Logo" style={{ maxWidth: '150px', marginBottom: '1rem' }} />
                        <h2 style={{ color: 'red', fontSize: '20px' }}>Regolamento</h2>
                        <div style={{ textAlign: 'left', lineHeight: '1.6', fontSize: '16px', color: 'black' }}>
                            E’ ritenuta valida un’attivazione che rispetta i seguenti requisiti:
                            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                                <li>1) Operatività mediante apparati 446 omologati e non modificati.</li>
                                <li>2)L’attività Pmr Montano puo’ essere svolta sia dalle referenze presenti nel programma FmMontano
                                    che dalle referenze presenti nel programma Radiomobile.</li>
                                <li>3) Per l’attivazione dalle Referenze FmMontano, si richiedono alla pari del programma
                                    radioamatoriale: 200 metri di dislivello e 30 minuti di percorrenza a piedi. E’ consentito
                                    L’uso di biciclette muscolari oppure a pedalata assistita in modalità Eco.</li>
                                <li>4)Per l’attivazione dalle referenze (check point) Radiomobile, è ritenuto valido il raggiungimento del
                                    target anche con un mezzo a motore.</li>
                                <li>5) I qso dovranno pervenire nell’apposita piattaforma di caricamento nonché sito web
                                    www.fmmontano.com.</li>
                                <li>6) E’ richiesto almeno 1 qso per rendere valida l’attivazione.</li>
                                <li>7) Il punteggio è calcolato alla pari di FmMontano e Radiomobile 1 punto al chilometro (Es: 100 km di
                                    qrb = 100 punti)</li>
                                <li>7) Il punteggio dei cacciatori è calcolato 1 punto per ogni qso concluso.</li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default RegolamentoPmr;