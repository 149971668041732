import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginComponent from './Login';
import Home from './Home';
import RegisterComponent from './RegisterComponent';
import FmAttivita from './FmAttivita';
import GestioneAttivita from './GestioneAttivita';// Importa il componente FmAttivita
import './App.css';
import AuthRoute from './AuthRoute'; // Assicurati di importare il componente AuthRoute
import { useDispatch } from 'react-redux';
import { setUserUUID, setNominativo } from './store';
import ElencoAttivita from './ElencoAttività';
import ClassificaAttivita from './ClassificaAttivita.jsx';
import PreLogin from './PreLogin';
import Regolamento from './Regolamento';
import ClassificaCaccia24 from './ClassificaCaccia24.jsx';
import ClassificaQrb from './ClassificaQrb.jsx';
import ClassificaPuntiStoria from './ClassificaPuntiStoria.jsx';
import MappaReferenze from './MappaReferenze.jsx';
import EmailImage from './EmailImage.jsx';
import Qsl from './Qsl.jsx';
import QsoImageDownload from './QsoImageDownload.jsx';
import Direttivo from './Direttivo.jsx';
import DiplomiPage from './DiplomiPage.jsx';
import HomeRadio from './HomeRadio.jsx';
import FmAttivitaRadio from './FmAttivitaRadio.jsx';
import GestioneAttivitaRadio from './GestioneAttivitaRadio.jsx';
import DirettivoRadio from './DirettivoRadio.jsx';
import RegolamentoRadio from './RegolamentoRadio.jsx';
import ElencoAttivitaRadio from './ElencoAttivitaRadio.jsx';
import MappaReferenzeRadio from './MappaReferenzeRadio.jsx';
import ClassificaAttivitaRadio from './ClassificaAttivitaRadio.jsx';
import ClassificaCaccia24Radio from './ClassificaCaccia24Radio.jsx';
import QslRadio from './QslRadio.jsx';
import EmailImageRadio from './EmailImageRadio.jsx';
import QsoImageDownloadRadio from './QsoImageDownloadRadio.jsx';
import HomePmr from './HomePmr.jsx';
import ElencoAttivitaRadioPmr from './ElencoAttivitaRadioPmr.jsx';
import DirettivoRadioPmr from './DirettivoRadioPmr.jsx';
import ClassificaAttivitaRadioPmr from './ClassificaAttivitaRadioPmr.jsx';
import ClassificaCaccia24RadioPmr from './ClassificaCaccia24RadioPmr.jsx';
import GestioneAttivitaRadioPmr from './GestioneAttivitaRadioPmr.jsx';
import PmrAttivita from './PmrAttivita.jsx';
import AuthRoutePmr from './AuthRoutePmr'; // Assicurati di importare il componente AuthRoute
import RegisterComponentPmr from './RegisterComponentPmr';
import LoginComponentPmr from './LoginPmr';
import RegolamentoPmr from './RegolamentoPmr.jsx';
import ClassificaCaccia25 from './ClassificaCaccia25.jsx';
import ClassificaAttivitaRadio2025 from './ClassificaAttivitaRadio2025.jsx';
import ClassificaAttivitaRadioPmr2025 from './ClassificaAttivitaRadioPmr2025.jsx';
import ClassificaCaccia25RadioPmr from './ClassificaCaccia25RadioPmr.jsx';
import ClassificaCaccia25Radio from './ClassificaCaccia25Radio.jsx';
import ClassificaAttivita2025 from './ClassificaAttivita2025.jsx';

export const AuthContext = createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const userUuid = sessionStorage.getItem('userUUID');
    const nominativo = sessionStorage.getItem('nominativo');
    if (userUuid) {
      dispatch(setUserUUID(userUuid));
    }
    if (nominativo) {
      dispatch(setNominativo(nominativo));
    }
  }, [dispatch]);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
            <Routes>
            <Route path="/" element={<PreLogin />} />
              <Route path="/login" element={<LoginComponent />} />
              <Route path="/loginPmr" element={<LoginComponentPmr />} />
              <Route path="/registerPmr" element={<RegisterComponentPmr />} />
              <Route path="/homePmr" element={<AuthRoutePmr><HomePmr /></AuthRoutePmr>} />
              <Route path="/elenco-attivitaPmr" element={<AuthRoutePmr><ElencoAttivitaRadioPmr /></AuthRoutePmr>} />
              <Route path="/classifica-attivitaPmr" element={<AuthRoutePmr><ClassificaAttivitaRadioPmr /></AuthRoutePmr>} />
              <Route path="/classifica-cacciatori-2024Pmr" element={<AuthRoutePmr><ClassificaCaccia24RadioPmr /></AuthRoutePmr>} />
              <Route path="/direttivoPmr" element={<AuthRoutePmr><DirettivoRadioPmr  /></AuthRoutePmr>} />
              <Route path="/pmrAttivita" element={<AuthRoutePmr><PmrAttivita /></AuthRoutePmr>} />
              <Route path="/gestione-attivitaPmr" element={<AuthRoutePmr><GestioneAttivitaRadioPmr /></AuthRoutePmr>} />
              <Route path="/register" element={<RegisterComponent />} />
              <Route path="/home" element={<AuthRoute><Home /></AuthRoute>} />
              <Route path="/regolamentoPmr" element={<AuthRoutePmr><RegolamentoPmr /></AuthRoutePmr>} />
              {/* Utilizza AuthRoute per proteggere la rotta di fmAttivita */}
              <Route path="/fmAttivita" element={<AuthRoute><FmAttivita /></AuthRoute>} />
              <Route path="/classifica-cacciatori-2025_radio" element={<AuthRoute><ClassificaCaccia25Radio /></AuthRoute>} />
              <Route path="/classifica-attivita_radio2025" element={<AuthRoute><ClassificaAttivitaRadio2025 /></AuthRoute>} />
              <Route path="/classifica-cacciatori-2025Pmr" element={<AuthRoutePmr><ClassificaCaccia25RadioPmr /></AuthRoutePmr>} />
              <Route path="/classifica-cacciatori-2025" element={<AuthRoute><ClassificaCaccia25 /></AuthRoute>} />
              <Route path="/classifica-attivitaPmr2025" element={<AuthRoutePmr><ClassificaAttivitaRadioPmr2025 /></AuthRoutePmr>} />
              <Route path="/classifica-attivita2025" element={<AuthRoute><ClassificaAttivita2025 /></AuthRoute>} />
              <Route path="/gestione-attivita" element={<AuthRoute><GestioneAttivita /></AuthRoute>} />
              <Route path="/regolamento" element={<AuthRoute><Regolamento /></AuthRoute>} />
              <Route path="/elenco-attivita" element={<AuthRoute><ElencoAttivita /></AuthRoute>} />
              <Route path="/classifica-attivita" element={<AuthRoute><ClassificaAttivita /></AuthRoute>} />
              <Route path="/classifica-cacciatori-2024" element={<AuthRoute><ClassificaCaccia24 /></AuthRoute>} />
              <Route path="/classifica-qrb" element={<AuthRoute><ClassificaQrb /></AuthRoute>} />
              <Route path="/classifica-punti-storica" element={<AuthRoute><ClassificaPuntiStoria /></AuthRoute>} />
              <Route path="/mappa" element={<AuthRoute><MappaReferenze /></AuthRoute>} />
              <Route path="/qsl" element={<AuthRoute><Qsl  /></AuthRoute>} />
              <Route path="/qso_elenco" element={<AuthRoute><EmailImage  /></AuthRoute>} />
              <Route path="/email-image" element={<AuthRoute><QsoImageDownload  /></AuthRoute>} />
              <Route path="/direttivo" element={<AuthRoute><Direttivo  /></AuthRoute>} />
              <Route path="/generale" element={<AuthRoute><DiplomiPage  /></AuthRoute>} />
              <Route path="/home_radio" element={<AuthRoute><HomeRadio /></AuthRoute>} />
              <Route path="/fmAttivita_radio" element={<AuthRoute><FmAttivitaRadio /></AuthRoute>} />
              <Route path="/gestione-attivita_radio" element={<AuthRoute><GestioneAttivitaRadio /></AuthRoute>} />
              <Route path="/direttivo_radio" element={<AuthRoute><DirettivoRadio  /></AuthRoute>} />
              <Route path="/regolamento_radio" element={<AuthRoute><RegolamentoRadio /></AuthRoute>} />
              <Route path="/elenco-attivita_radio" element={<AuthRoute><ElencoAttivitaRadio /></AuthRoute>} />
              <Route path="/mappa_radio" element={<AuthRoute><MappaReferenzeRadio /></AuthRoute>} />
              <Route path="/classifica-attivita_radio" element={<AuthRoute><ClassificaAttivitaRadio /></AuthRoute>} />
              <Route path="/classifica-cacciatori-2024_radio" element={<AuthRoute><ClassificaCaccia24Radio /></AuthRoute>} />
              <Route path="/qsl_radio" element={<AuthRoute><QslRadio  /></AuthRoute>} />
              <Route path="/qso_elenco_radio" element={<AuthRoute><EmailImageRadio  /></AuthRoute>} />
              <Route path="/email-image_radio" element={<AuthRoute><QsoImageDownloadRadio  /></AuthRoute>} />
            </Routes>
          </AuthContext.Provider>
        </header>
        <footer className="footer text-center">
          Creata da: Giacomo Frola IU1LBK
        </footer>
      </div>
    </Router>
  );
}

export default App;
