import React, { useState, useEffect } from 'react';
import { Container, Table, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FaMedal, FaListAlt, FaMapMarkedAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaEdit, FaSignOutAlt, FaHome, FaBook, FaTrophy, FaUserTie } from 'react-icons/fa';
import { resetUser } from './store';

function ClassificaCaccia24RadioPmr() {
    const [classifica, setClassifica] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const nominativo = sessionStorage.getItem('nominativo');

    useEffect(() => {
        // Qui va la chiamata al servizio per ottenere la classifica
        fetchClassifica();
    }, []);

    const fetchClassifica = async () => {
        const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
        try {
            const response = await fetch('/fmMontano/classificaCacciatori_radioPmr', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': basicAuth,
                },
            });
            if (response.ok) {
                const datiClassifica = await response.json();
                setClassifica(datiClassifica);
            } else {
                console.error("Errore nel recupero della classifica");
            }
        } catch (error) {
            console.error("Errore di connessione al servizio di classifica", error);
        }
    };

    const handleLogout = () => {
        sessionStorage.clear();
        dispatch(resetUser());
        navigate('/login');
    };

    const medaglia = (posizione) => {
        switch (posizione) {
            case 0:
                return <FaMedal style={{ color: 'gold' }} />;
            case 1:
                return <FaMedal style={{ color: 'silver' }} />;
            case 2:
                return <FaMedal style={{ color: 'bronze' }} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Navbar fixed="top" expand="false" style={{
                borderBottom: '1px solid #dee2e6',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Uguale al contenitore del messaggio
                backdropFilter: 'blur(5px)', // Opzionale: mantiene l'effetto sfocatura
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Consistente con lo stile del contenitore del messaggio
            }}>
                <Container>
                <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <img src="/pmr.png" alt="Pmr Logo" style={{ height: '40px', marginRight: '10px' }} />
                        Pmr Montano
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={() => navigate('/regolamentoPmr')} style={{ color: 'black' }}>
                                <FaBook /> Regolamento
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/gestione-attivitaPmr')} style={{ color: 'black' }}>
                                <FaEdit /> Gestione Attività
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/elenco-attivitaPmr')} style={{ color: 'black' }}>
                                <FaListAlt /> Elenco Attività
                            </Nav.Link>


                            {/* Dropdown per le classifiche */}
                            <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                            <NavDropdown.Item onClick={() => navigate('/classifica-attivitaPmr2025')}>Classifica Attivatori 2025</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-attivitaPmr')}>Classifica Attivatori 2024</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2025Pmr')}>Classifica Cacciatori 2025</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link onClick={() => navigate('/direttivoPmr')} style={{ color: 'black' }}>
                                <FaUserTie /> Direttivo
                            </Nav.Link>

                            <Nav.Link onClick={() => navigate('/homePmr')} style={{ color: 'black' }}>
                                <FaHome /> Home
                            </Nav.Link>


                            <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                                <FaSignOutAlt /> Logout
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "95vh",
                    backgroundImage: 'url("/login_radio.webp")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    paddingTop: '75px' // Aggiungi un padding top per compensare l'altezza della navbar fissa
                }}
            >
                <Container>
                    <h1 style={{ color: 'red', textShadow: '2px 2px 4px #ffffff' }}>Classifica Cacciatori 2024</h1>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Posizione</th>
                                <th>Cacciatore</th>
                                <th>Punti</th>
                            </tr>
                        </thead>
                        <tbody>
                            {classifica.map((attivita, index) => (
                                <tr key={index}>
                                    <td>
                                        {index + 1} {medaglia(index)}
                                    </td>
                                    <td style={{ color: (nominativo.trim().toUpperCase() === attivita.cacciatore.trim().toUpperCase() ? 'red' : 'black') }}>
                                        {attivita.cacciatore}
                                    </td>
                                    <td style={{ color: (nominativo.trim().toUpperCase() === attivita.cacciatore.trim().toUpperCase() ? 'red' : 'black') }}>
                                        {attivita.conteggio}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </div>
        </>
    );
}

export default ClassificaCaccia24RadioPmr;
